.loading-xs {
    width: 0.5rem !important;
    animation: scaleUpDown-xs 2s ease-in-out infinite;
}

.loading-sm {
    width: 0.65rem !important;
    animation: scaleUpDown-xs 2s ease-in-out infinite;
    animation-delay: 0.1s;
}

.loading-md {
    width: 0.75rem !important;
    animation: scaleUpDown-xs 2s ease-in-out infinite;
    animation-delay: 0.2s;
}
.loading-lg {
    width: 0.85rem !important;
    animation: scaleUpDown-xs 2s ease-in-out infinite;
    animation-delay: 0.3s;
}
@keyframes scaleUpDown-xs {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(2.5);
    }
    100% {
        transform: scale(1);
    }
}

.recordOn {
    animation: zoomInOut 1s alternate infinite ease-in-out;
}

@keyframes zoomInOut {
    0% {
        transform: scale(0.8);
    }
    100% {
        transform: scale(1);
    }
}

/* Apply the animation to the element */
.zoom {
    animation: scaleAnimation 2s infinite ease;
    transform: scale(1);
}

@keyframes scaleAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}

.slick-dots li {
    background-color: gray !important;
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
}
.slick-dots .slick-active {
    background-color: #00a76f !important;
    border-radius: 50%;
    width: 10px !important;
    height: 10px !important;
}
.slick-dots button {
    opacity: 0;
}

.dataHover:hover button {
    opacity: 1 !important;
}
