.swal-dark-mode {
    background-color: #333;
    color: #fff;
}

.swal-dark-mode-confirm {
    background-color: #00a76f;
    color: #fff;
}

.swal-dark-mode-cancel {
    background-color: #d32f2f;
    color: #fff;
}
