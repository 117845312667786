.file {
    cursor: pointer;
    opacity: 0;
    height: 100%;
    width: 100%;
    z-index: 9;
}
.file ::file-selector-button {
    height: 100%;
    width: 100%;
    cursor: pointer;
}

/* Styling for the focused fieldset */
fieldset.border-2:focus-within {
    border-color: #00a76f;
}

/* Change legend text color when fieldset is focused */
fieldset.border-2:focus-within legend {
    color: #00a76f;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 40px white inset !important;
}

.PhoneInput input {
    height: 40px !important;
    background-color: unset !important;
    outline: none !important;
}
.PhoneInputInternationalIconPhone {
    display: none !important;
}
