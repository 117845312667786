@tailwind utilities;
.textEditor .ql-editor {
    height: 10rem;
}
.textEditor .ql-snow {
    border: 0 !important;
}

@layer utilities {
    .textEditorLight .ql-toolbar {
        background-color: #e5e7eb;
    }
    .textEditorDark .ql-toolbar {
        background-color: #24363f;
    }
    .textEditorDark .ql-snow .ql-stroke {
        stroke: white;
    }
    .textEditorDark .ql-snow .ql-picker {
        color: white;
    }
    .textEditorDark .ql-snow .ql-picker-options {
        background-color: #24363f;
        border: none;
    }
}

.PhoneInput input {
    height: 50px !important;
    background-color: unset !important;
    outline: none !important;
}
.PhoneInputInternationalIconPhone {
    display: none !important;
}
