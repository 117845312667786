@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Public+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./style/chartStyle.css");
@import url("./style/animationStyle.css");
@import url("./style/inputFieldStyle.css");
@import url("./style/sweetAlertStyle.css");
@import url("./style/textEditor.css");

@layer components {
    .backgroundLinear {
        background: linear-gradient(135deg, rgba(91, 228, 155, 0.2), rgba(0, 167, 111, 0.1)), rgb(255, 255, 255, 0.5);
    }

    .backgroundLinearDark {
        background: linear-gradient(135deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), rgb(0, 0, 0, 0.5);
    }
    .bgImage {
        background-image: url("./assets/images/login/background.svg");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .signInBG {
        background-image: url("./assets/images/login/singInBG.png");
        background-size: cover;
        background-repeat: no-repeat;
    }
    .date-input::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
}

.active div {
    color: #00a76f;
    background-color: rgba(0, 167, 111, 0.08);
    border-radius: 10px;
}
.active span {
    background-color: #00a76f;
}
.active h2 {
    color: #00a76f;
    font-weight: 500;
}

html {
    font-family: "Public Sans", sans-serif;
    overflow-x: hidden !important;
}

.profileImage > span {
    opacity: 0;
}

.profileImage:hover > span {
    opacity: 1;
}

.backDrop::before {
    content: "";
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    position: absolute;
    background: linear-gradient(rgba(22, 28, 36, 0) 0%, rgb(22, 28, 36) 85%);
}

.shadowBottom {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.1);
}

.swal-dark-mode {
    background-color: #212b36 !important;
}
